
<template>
  <section
      v-if="isOpen"
      class="fixed top-0 left-0 w-full h-screen z-50 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center overflow-hidden overflow-y-auto"
      @click="handleClose"
  >
    <div
      @click.stop=""
      :class="`${calculateSize(size)} bg-white rounded-lg max-h-85% mx-auto border shadow-xl overflow-y-auto scrollbar scale-in-animation ${wrapperClassNames}`"
      :style="style"
      >
      <div v-if="title" class="z-10 h-16 bg-white border-b shadow-inner sticky top-0 text-sm md:text-base flex justify-between items-center px-3 md:px-4 lg:px-6">
        <h2 class="text-xl font-medium">{{ title }}</h2>
        <button @click="handleClose" class="text-4xl">&times;</button>
      </div>
      <div :class="`relative ${bodyClassNames}`">
        <button v-if="!title" @click="handleClose" class="absolute right-4 top-1 text-4xl">&times;</button>
        <slot />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NewModal",
  props: ['isOpen', 'wrapperClassNames', 'size', 'bodyClassNames', 'title', 'style'],
  methods: {
    handleClose() {
      this.$emit('onClose');
    },
    calculateSize(size = 'regular') {
      switch (size) {
        case 'small':
          return 'w-11/12 md:w-520';
        case 'regular':
          return 'w-11/12 md:w-620';
        case 'medium':
          return 'w-11/12 md:w-9/12 lg:w-820';
        case 'large':
          return 'w-11/12 md:w-10/12 lg:w-9/12';
        default:
          return 'w-11/12 md:w-6/12';
      }
    }
  }
}
</script>

<style scoped>

</style>